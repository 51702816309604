import React, { useState, useEffect, CSSProperties, useRef, ComponentProps } from 'react';
import { TextVariants } from '~/shared/components';
import { springDefault } from '~/theme/themes/animations/baseAnimations';
import { TextShade } from '../Text';
import { StyledStrokeText } from './styled';

export type StrokeTextMode = 'whileHover' | 'once';
export type StrokeTextHeight = number | string;
type StrokeTextAnimationstate = 'active' | 'idle';
type StrokeTextVariant = 'expanded' | 'compressed';

export type StrokeTextProps = {
    text?: string;
    shade?: TextShade;
    strokeMode?: StrokeTextMode;
    strokeHeight?: StrokeTextHeight;
    strokeColor?: string;
    style?: CSSProperties;
    variant?: TextVariants;
} & ComponentProps<'span'>;

// 'once' has the StrokeText expanded initially. On hover it will animate once and return to starting position
// 'whileHover' has the StrokeText collapsed initially. While hovered it will expand
const strokeModes: {
    [i in StrokeTextMode]: {
        [j in StrokeTextAnimationstate]: StrokeTextVariant;
    };
} = {
    whileHover: {
        active: 'expanded',
        idle: 'compressed',
    },
    once: {
        active: 'compressed',
        idle: 'expanded',
    },
};

const strokeVariants = {
    compressed: {
        backgroundSize: '0% 100%',
        backgroundPosition: '100% 100%',
    },
    expanded: {
        backgroundSize: '100% 100%',
        backgroundPosition: '0% 100%',
    },
};

/**
 * @description
 * Adds an animatable underline or a background to a relative positioned element.
 * To trigger effect on hover on a parent element, simply add the data-stroketext-trigger attribute to the desired element.
 * @param { string } [text] text string that needs the hover effect
 * @param {'whileHover' | 'once'} [strokeMode = 'once'] If the line should animate once on activation, or keep its activation while being hovered
 * @param { number | string } [strokeHeight = 1] The height of StrokeText. Use e.g. 100% if you want it as a complete background
 * @param { string } [strokeColor = 'currentcolor'] color of the strokeline
 * @param { CSSProperties } [style] custom styling if needed
 */
export const StrokeText = React.memo(
    ({
        text,
        shade,
        variant,
        strokeMode = 'whileHover',
        strokeHeight = 1,
        strokeColor = 'currentColor',
        style,
        className,
    }: StrokeTextProps) => {
        const [animationState, setAnimationState] = useState<StrokeTextAnimationstate>('idle');
        const [isHovered, setIsHovered] = useState(false);
        const [transitionActive, setTransitionActive] = useState(false);
        const spanRef = useRef<HTMLSpanElement>(null);

        useEffect(() => {
            if (transitionActive) {
                return;
            }
            setAnimationState(isHovered ? 'active' : 'idle');
        }, [isHovered, transitionActive, setAnimationState]);

        const handleMouseEnter = () => setIsHovered(true);
        const handleMouseLeave = () => setIsHovered(false);

        useEffect(() => {
            if (!spanRef.current) {
                return;
            }
            const parentAnchor = spanRef.current.closest('[data-stroketext-trigger], a, button');
            parentAnchor?.addEventListener('mouseenter', handleMouseEnter);
            parentAnchor?.addEventListener('mouseleave', handleMouseLeave);
            return () => {
                parentAnchor?.removeEventListener('mouseenter', handleMouseEnter);
                parentAnchor?.removeEventListener('mouseleave', handleMouseLeave);
            };
        }, [spanRef]);

        return (
            <StyledStrokeText
                className={className}
                variant={variant}
                as="span"
                ref={spanRef}
                transition={{
                    ...springDefault,
                    backgroundPosition: {
                        duration: 0,
                    },
                }}
                shade={shade}
                strokeColor={strokeColor}
                hasText={!!text}
                style={style}
                strokeHeight={strokeHeight}
                variants={strokeVariants}
                animate={strokeModes[strokeMode][animationState]}
                initial={false}
                onAnimationStart={() => setTransitionActive(true)}
                onAnimationComplete={() => setTransitionActive(false)}
            >
                {text}
            </StyledStrokeText>
        );
    }
);
