import styled from '@emotion/styled';
import { m } from 'framer-motion';
import { ifProp, switchProp } from 'styled-tools';
import { cover } from '~/shared/utils/styled';
import { pxToRem } from '~/theme';
import { ButtonProps } from './Button';

const extraClickableAreaVertical = '0.2em';
const extraClickableAreaHorizontal = '0.3em';

export const StyledResetButton = styled.button(({ theme }) => ({
    alignItems: 'center',
    appearance: 'none',
    border: 'none',
    boxSizing: 'border-box',
    display: 'inline-flex',
    cursor: 'pointer',
    flexShrink: 0,
    lineHeight: 1,
    letterSpacing: 0,
    margin: 0,
    padding: 0,
    textRendering: 'inherit',
    textDecoration: 'none',
    WebkitUserSelect: 'none',
    userSelect: 'none',
    WebkitTapHighlightColor: 'rgba(0,0,0,0)',
    borderRadius: 0,
    backgroundColor: 'transparent',
    color: 'inherit',
    fontFamily: theme.fonts.body,
}));

export const StyledButton = styled(m(StyledResetButton))<ButtonProps>(
    ({ theme }) => ({
        position: 'relative',
        padding: '1em 1.4em',
        fontFamily: theme.fonts.body,
        fontWeight: theme.fontWeights.regular,
        gap: theme.spaces[3], // multiple children
        justifyContent: 'center',
        pointerEvents: 'auto',
        svg: {
            height: 24,
            width: 24,
        },
        ':disabled': {
            opacity: 0.5,
            cursor: 'default',
            pointerEvents: 'none',
        },
    }),

    switchProp('size', {
        sm: ({ theme: { fontSizes, buttons, spaces } }) => ({
            fontSize: fontSizes.xs,
            lineHeight: fontSizes.md,
            fontWeight: buttons.fontWeights.sm,
            padding: `${spaces[2]} ${spaces[3]}`,
            gap: spaces[2],
            svg: {
                height: 16,
                width: 16,
            },
        }),
        md: ({ theme: { fontSizes, buttons, spaces } }) => ({
            fontSize: fontSizes.sm,
            lineHeight: fontSizes['2xl'],
            fontWeight: buttons.fontWeights.md,
            padding: `${pxToRem(12)} ${spaces[5]}`,
            gap: spaces[2],
            svg: {
                height: 24,
                width: 24,
            },
        }),
        lg: ({ theme: { fontSizes, buttons, spaces } }) => ({
            fontSize: fontSizes.lg,
            lineHeight: fontSizes['3xl'],
            fontWeight: buttons.fontWeights.lg,
            padding: `${pxToRem(12)} ${spaces[6]}`,
            svg: {
                height: 24,
                width: 24,
            },
        }),
    }),

    switchProp('variant', {
        primary: ({ theme, shade }) => {
            const { colors } = theme;
            const isDark = shade === 'dark';

            return {
                color: isDark ? colors.light : colors.dark,
                backgroundColor: isDark ? colors.dark : colors.light,
            };
        },
        secondary: ({ theme, shade }) => {
            const { colors } = theme;
            const isDark = shade === 'dark';
            return {
                color: isDark ? colors.dark : colors.light,
                backgroundColor: isDark ? colors.light : colors.dark,
                boxShadow: `0 0 0 1px ${isDark ? colors.dark70 : colors.light30} inset`,
            };
        },
        tertiary: ({ theme, shade }) => {
            const isDark = shade === 'dark';
            return {
                backgroundColor: 'transparent',
                color: isDark ? theme.colors.dark : theme.colors.light,
                height: 'auto',
                padding: `${extraClickableAreaVertical} ${extraClickableAreaHorizontal}`,
                margin: `-${extraClickableAreaVertical} -${extraClickableAreaHorizontal}`,
                fontFamily: theme.fonts.body,
                fontWeight: theme.fontWeights.regular,

                '&:hover svg': {
                    opacity: 0.8,
                },
            };
        },
    }),
    switchProp('shape', {
        icon: ({ size }) => ({
            height: 'auto',
            margin: 0,
            padding: size === 'sm' ? pxToRem(8) : pxToRem(12),
        }),
    }),
    ifProp({ rounded: true }, ({ theme }) => ({
        overflow: 'hidden',
        borderRadius: theme.spaces[1],
    }))
);

export const StyledButtonContent = styled.span<{ size: ButtonProps['size'] }>(
    ({ theme }) => ({
        position: 'relative',
        zIndex: 2,
        alignItems: 'center',
        display: 'inline-flex',
        textAlign: 'center',
        gap: theme.spaces[3],
    }),

    switchProp('size', {
        sm: ({ theme }) => ({
            gap: theme.spaces[2],
        }),
        md: ({ theme }) => ({
            gap: theme.spaces[2],
        }),
        lg: ({ theme }) => ({
            gap: theme.spaces[3],
        }),
    })
);

// This is needed because of the boxShadow border on 'secondary' variants,
// and underlineOffset on 'tertiary' variant
export const StyledButtonLineWrapper = styled.span<Pick<ButtonProps, 'variant' | 'size'>>(
    {
        ...cover,
        zIndex: 0,
        overflow: 'hidden',
        pointerEvents: 'none',
        borderRadius: 'inherit',
    },
    switchProp('variant', {
        secondary: {
            inset: 1,
        },
        tertiary: ({ theme, size }) => {
            const underLineOffsetLookup = {
                sm: theme.spaces[1],
                md: pxToRem(6),
                lg: theme.spaces[2],
            };
            return {
                left: extraClickableAreaHorizontal,
                right: extraClickableAreaHorizontal,
                bottom: `calc(${extraClickableAreaVertical} - ${
                    underLineOffsetLookup[size || 'lg']
                })`,
            };
        },
    })
);
