import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { useBasket } from '~/features/basket';
import { LineItemViewModel, OrderTrackingModel, VoucherViewModel } from '~/lib/data-contract';
import { gtmProductMapper, useGtm } from '~/shared/utils';
import { useFranchise } from '~/shared/utils/franchise';
import { useProduct } from '~/shared/utils/product';
import { usePage } from '~/templates/pages';

export const useTracking = () => {
    const { trackCheckout, trackPurchase, trackCustomEvent } = useGtm();
    const { data: basket } = useBasket();
    const { asPath } = useRouter();
    const { data: product } = useProduct();
    const { activeMarket } = useFranchise();
    const { type } = usePage();
    const lines = useMemo(() => gtmProductMapper(basket?.lines || []), [basket?.lines]);
    const isCheckout = type === 'p100CheckoutPage';

    const trackCheckoutStep = (step: number, shippingMethodId?: string) => {
        if (!basket?.lines?.length) {
            return;
        }

        trackCheckout(lines, step, activeMarket?.currency, shippingMethodId);
    };

    const trackCheckoutOrderStep = (
        orderLines?: LineItemViewModel[],
        currencyCode?: string,
        paymentMethodUsed?: string
    ) => {
        if (!orderLines?.length) {
            return;
        }

        trackCheckout(gtmProductMapper(orderLines), 4, currencyCode, paymentMethodUsed);
    };

    const trackOrderPurchase = (order?: OrderTrackingModel, vouchers?: VoucherViewModel[]) => {
        if (!order?.products?.length) {
            return;
        }

        const { id, products, revenue, currencyCode, shipping, tax } = order;

        trackPurchase(
            id,
            gtmProductMapper(products),
            revenue,
            currencyCode,
            shipping,
            tax,
            vouchers?.map((voucher) => voucher.name || '')
        );
    };

    const trackClubSignUp = () => {
        const eventLabel = isCheckout ? 'checkout' : 'signup';
        trackCustomEvent({
            event: 'gaEvent',
            eventCategory: 'Kundeklub',
            eventAction: 'Sign-up',
            eventLabel,
        });
    };

    const trackPageView = () => {
        trackCustomEvent({
            event: 'pageview',
            pageURI: asPath,
        });
    };

    const trackProductReservation = () => {
        trackCustomEvent({
            event: 'gaEvent',
            eventCategory: 'Reserve & Collect',
            eventAction: 'Reserve - Product',
            eventLabel: product?.name,
        });
    };

    const trackStoreReservation = (storeName: string) => {
        trackCustomEvent({
            event: 'gaEvent',
            eventCategory: 'Reserve & Collect',
            eventAction: 'Reserve - Butik',
            eventLabel: storeName,
        });
    };

    return {
        trackCheckoutStep,
        trackCheckoutOrderStep,
        trackOrderPurchase,
        trackClubSignUp,
        trackPageView,
        trackProductReservation,
        trackStoreReservation,
    };
};
