import { useTheme } from '@emotion/react';
import { readableColorIsBlack as color2kReadableColorIsBlack } from 'color2k';
import { useMemo } from 'react';
import { ButtonProps, ImageProps, TextShade } from '~/shared/components';
import { LineShade } from '~/shared/components/Line/Line';
import { getTextShade, PotentialThemeColorType, usePotentialThemeColor } from '~/theme';

export type UseThemeShadeProps = {
    backgroundColor?: PotentialThemeColorType;
};

export type GeneratedThemeShade = {
    backgroundColor: string;
    headlineShade: TextShade;
    textShade: TextShade;
    buttonShade: Exclude<ButtonProps['shade'], undefined>;
    skeletonShade: Exclude<ImageProps['skeletonShade'], undefined>;
    lineShade: LineShade;
    readableColorShade: 'light' | 'dark';
    backgroundShade: 'light' | 'dark';
};

export const useThemeShade = ({ backgroundColor }: UseThemeShadeProps): GeneratedThemeShade => {
    const { colors } = useTheme();

    const backgroundColorValue = usePotentialThemeColor(backgroundColor || '');

    const readableColorIsBlack = color2kReadableColorIsBlack(backgroundColorValue || colors.light);

    const readableColorShade = readableColorIsBlack ? 'dark' : 'light';
    const backgroundShade = readableColorIsBlack ? 'light' : 'dark';

    // Get text color from background color if not set
    const headlineShade = useMemo(() => {
        // Fallback to background color
        return getTextShade(backgroundColorValue || colors.light, 'headline');
    }, [backgroundColorValue, colors.light]);

    const textShade = useMemo(() => {
        // Fallback to background color
        return getTextShade(backgroundColorValue || colors.light);
    }, [backgroundColorValue, colors.light]);

    // Get button shade based on background color
    const buttonShade = useMemo<Exclude<ButtonProps['shade'], undefined>>(() => {
        return readableColorIsBlack ? 'dark' : 'light';
    }, [readableColorIsBlack]);

    // Get skeleton shade based on background color
    const skeletonShade = useMemo<Exclude<ImageProps['skeletonShade'], undefined>>(() => {
        return readableColorIsBlack ? 'light' : 'dark';
    }, [readableColorIsBlack]);

    // Get line shade based on background color
    const lineShade = useMemo<LineShade>(() => {
        return readableColorIsBlack ? 'colorInverted' : 'color';
    }, [readableColorIsBlack]);

    return {
        backgroundColor: backgroundColorValue,
        headlineShade,
        textShade,
        buttonShade,
        skeletonShade,
        lineShade,
        backgroundShade,
        readableColorShade,
    };
};
