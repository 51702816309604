import { OrderProductTrackingModel } from '~/lib/data-contract';
import { ProductWithQuantity } from '../model';

export const orderProductTrackingViewModelMapper = (products: OrderProductTrackingModel[]) => {
    const gtmProducts: ProductWithQuantity[] = [];
    products.forEach((item) => {
        const { category, brand, variant } = item;
        const trackingName = `${brand} ${category} ${variant}`;
        gtmProducts.push({
            ...item,
            name: trackingName,
        });
    });

    return gtmProducts;
};
