import styled from '@emotion/styled';
import { Button } from '$shared/components/Button';
import { breakpoints, pxToRem } from '~/theme';
import { m } from 'framer-motion';

const iconWidth = 24;

export const StyledChatButtonWrapper = styled.div({
    [breakpoints.xs]: {
        display: 'none',
    },
});

export const StyledChatButtonContainer = styled(m.div)(({ theme }) => ({
    position: 'fixed',
    zIndex: theme.zIndices[1],
    right: theme.spaces[4],
    bottom: theme.spaces[4],
    overflow: 'hidden',
    pointerEvents: 'none',
    borderRadius: 100, // Arbitrary high value to make sure we cover. Can't use percentage because button is oblong
}));

export const StyledChatButtonMotionWrapper = styled(m.div)({
    borderRadius: 'inherit',
    overflow: 'hidden',
});

export const StyledChatButton = styled(Button)(({ theme }) => ({
    padding: `${pxToRem(12)} ${theme.spaces[4]}`,

    '&:active': {
        svg: {
            transform: 'scale(1.05)',
            transitionDuration: theme.animations.getDuration('fast01'),
        },
    },

    svg: {
        margin: theme.spaces[1],
        color: theme.colors.light,
        width: iconWidth,
        height: iconWidth,
        transition: `transform ${theme.animations.getDuration(
            'slow01'
        )} ${theme.animations.getEasing('standard', 'productive')}`,
    },
}));
