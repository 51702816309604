import React, { memo, ReactNode, useMemo } from 'react';
import { StyledButton, StyledButtonContent, StyledButtonLineWrapper } from './styled';
import type {
    PolymorphicForwardRefExoticComponent,
    PolymorphicPropsWithoutRef,
} from 'react-polymorphic-types';
import { VisuallyHidden } from '@radix-ui/react-visually-hidden';
import { useTheme } from '@emotion/react';
import { StrokeText } from '~/shared/components';

const ButtonDefaultElement = 'button';

export const buttonVariants = ['primary', 'secondary', 'tertiary'] as const;
export const buttonShapes = ['default', 'icon'] as const;
export const buttonShades = ['dark', 'light'] as const;
export const buttonSizes = ['sm', 'md', 'lg'] as const;
export type ButtonVariant = typeof buttonVariants[number];
export type ButtonShape = typeof buttonShapes[number];
export type ButtonShade = typeof buttonShades[number];
export type ButtonSize = typeof buttonSizes[number];
export type ButtonProps = {
    /**
     * The size of the button
     */
    size?: ButtonSize;
    /**
     * Which theme variant to display button as
     * primary: solid background, without border
     * secondary: transparent background, with border
     * tertiary: transparent background, without border
     */
    variant?: ButtonVariant;

    /**
     * The usecase determines the shape
     */
    shape?: ButtonShape;

    /**
     * Which shade the button has. Useful for background distinction
     */
    shade?: ButtonShade;

    /**
     * Set to false if the Strokeline should not be shown. Child SVG's of the 'tertiary' variant will still change colour
     */
    showHoverIndicator?: boolean;

    /**
     * Description of what the button does, this is for screen readers
     * and should be used when content does not describe the action
     */
    description?: string;
    children?: ReactNode;

    /**
     * Rounded buttons should ideally be a variant or theme property, but for now we'll keep it as a prop becuase implementation and design is inconsistent.
     */
    rounded?: boolean;
};

export const Button: PolymorphicForwardRefExoticComponent<
    ButtonProps,
    typeof ButtonDefaultElement
> = React.forwardRef(
    <T extends React.ElementType = typeof ButtonDefaultElement>(
        {
            as,
            variant = 'primary',
            shade = 'dark',
            shape = 'default',
            size = 'lg',
            showHoverIndicator = true,
            description,
            rounded = false,
            children,
            ...restProps
        }: PolymorphicPropsWithoutRef<ButtonProps, T>,
        ref: React.ForwardedRef<Element>
    ) => {
        return (
            <StyledButton
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                ref={ref as any}
                as={as || ButtonDefaultElement}
                variant={variant}
                shade={shade}
                shape={shape}
                size={size}
                rounded={rounded}
                {...restProps}
            >
                {description && <VisuallyHidden>{description}</VisuallyHidden>}
                <StyledButtonContent aria-hidden={!!description} size={size}>
                    {children}
                </StyledButtonContent>
                {showHoverIndicator && <ButtonLine {...{ variant, shade, size }} />}
            </StyledButton>
        );
    }
);

const ButtonLine = memo(
    ({ variant, size, shade }: Pick<ButtonProps, 'variant' | 'size' | 'shade'>) => {
        const { colors } = useTheme();
        const strokeColor: string | undefined = useMemo(() => {
            const isDark = shade === 'dark';
            switch (variant) {
                case 'primary':
                    return isDark ? colors.dark70 : colors.light30;
                case 'secondary':
                    return isDark ? colors.light30 : colors.dark70;
                case 'tertiary':
                    return isDark ? colors.dark : colors.light;
                default:
                    return;
            }
        }, [variant, shade, colors]);
        switch (variant) {
            case 'primary':
            case 'secondary':
                return (
                    <StyledButtonLineWrapper variant={variant}>
                        <StrokeText strokeHeight="100%" strokeColor={strokeColor} />
                    </StyledButtonLineWrapper>
                );
            case 'tertiary':
                return (
                    <StyledButtonLineWrapper variant={variant} size={size}>
                        <StrokeText strokeMode="once" strokeColor={strokeColor} />
                    </StyledButtonLineWrapper>
                );
            default:
                return <></>;
        }
    }
);
