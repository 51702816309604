import { useMemo } from 'react';
import { useFranchise } from '~/shared/utils';
import { useProduct } from '~/shared/utils/product';
import { addResizeVariablesToProductImage } from '$shared/utils/product';

// Create schema for product
export const useSchemaMarkupProduct = () => {
    const { activeMarket } = useFranchise();
    const { data: product } = useProduct();

    const productSchema = useMemo(() => {
        if (!product) {
            return null;
        }

        const {
            productId,
            name,
            canonical,
            images,
            brand,
            metaDescription,
            sizes,
            masterCategory,
            priceDetails,
        } = product;
        const inStock = sizes?.some((size) => size.inStockOnWeb);

        // If we don't do this, we'll just get the full-size version, and... that is very, very bad.
        const imageURL = addResizeVariablesToProductImage(images?.[0]?.url);

        return JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'Product',
            productId,
            name,
            url: canonical,
            image: imageURL,
            brand: {
                '@type': 'Brand',
                name: brand,
            },
            category: masterCategory,
            description: metaDescription,
            offers: {
                '@type': 'Offer',
                availability: inStock ? 'InStock' : 'NoStock',
                price: priceDetails?.rawSalePrice,
                priceCurrency: activeMarket?.currency,
            },
        });
    }, [product, activeMarket?.currency]);

    return productSchema;
};
