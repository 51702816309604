import { useQuery } from 'react-query';
import { ProductViewModel } from '~/lib/data-contract';
import { fetchProduct } from '~/lib/fetchProduct';
import { useFranchise } from '~/shared/utils';
import { usePage } from '~/templates/pages';

export const useProduct = () => {
    const { queryKey, franchiseAPIPath } = useFranchise();
    const { detail } = usePage();

    const productId = detail?.productId || '';
    const productColorId = detail?.productColor || '';

    return useQuery<ProductViewModel, Error>(
        ['product', { queryKey, productId, productColorId }],
        () => fetchProduct({ productId, productColorId, franchiseAPIPath }),
        {
            enabled: Boolean(productId && productColorId),
            retry: false,
        }
    );
};
