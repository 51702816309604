import { UpdateNewsletterArgs } from '~/lib/data-contract';
import { patchRequest } from '~/shared/utils';

export async function editPermissions(
    payload: UpdateNewsletterArgs,
    apiUrl: string,
    headers?: HeadersInit
) {
    const request = await patchRequest<void, UpdateNewsletterArgs>(apiUrl, payload, headers);

    const response = await request();

    return response;
}
