import { ImageTagViewModel } from '~/lib/data-contract';

/**
 * Map function for image tags to prettify dimension13
 * should be spread to conditionally include the property
 * @example
 * {
 *     ...getImageTags(imageTags)
 * }
 */
export const getImageTags = (tags?: ImageTagViewModel[]) =>
    tags?.length ? { dimension13: tags.map((tag) => tag.text).join(' | ') } : {};
