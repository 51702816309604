import { useRouter } from 'next/router';
import { useQuery } from 'react-query';
import { useAccount } from '~/features/club';
import { Pages, PagesWithDetail, Redirect } from '~/lib/data-contract';
import { fetchPage } from '~/lib/fetchPage';
import { useLogging } from '~/shared/hooks';
import { useFranchise } from '~/shared/utils';

export const usePage = <T = Pages>(): T => {
    const { asPath, locale } = useRouter();
    const { authHeaders: headers } = useAccount();
    const { queryKey, key } = useFranchise();
    const { customException } = useLogging();
    const path = decodeURIComponent(asPath.split(/[?#]/)[0]);
    const pageQueryKey = ['page', { queryKey, path }];
    const url = `${locale}${path}`;

    const { data: page = {} } = useQuery<PagesWithDetail | Redirect, Error>(
        pageQueryKey,
        () => fetchPage(`${locale}${path}`, key, {}, headers),
        {
            retry: false,
            staleTime: 10 * 60 * 1000, // 10 minutes
            cacheTime: 60 * 1000, // 1 minutes
            refetchOnWindowFocus: true,
            keepPreviousData: true,
            onError: () => {
                customException(`Client page request failed! url: ${url}`);
            },
        }
    );

    return (page as unknown) as T;
};
