import { TranslationKey } from '~/lib/data-contract';
import { Translations } from '..';

/**
 * Returns an array for namespaced keys
 *
 * @example
 * {
 *   a: {
 *     b: {
 *       c: 'd'
 *      }
 *    }
 * }
 *
 * returns {
 *   'a.b.c': 'd'
 * }
 *
 * Inspired by https://stackoverflow.com/a/44066425
 */
export function namespacedKeys(data: Record<string, string>, prev = ''): Translations {
    let result: Partial<Translations> = {};

    for (const key in data) {
        const dot = prev.length ? '.' : '';
        const value = data[key];

        if (typeof value == 'object') {
            result = {
                ...namespacedKeys(value, prev + dot + key),
                ...result,
            };
        } else {
            const namespacedKey = (prev + dot + key) as TranslationKey;
            result[namespacedKey] = value;
        }
    }

    return result as Translations;
}
