import { LineItemViewModel } from '~/lib/data-contract';
import { getImageTags } from './getImageTags';
import { ProductWithQuantity } from '../model';

export const lineItemViewModelMapper = (products: LineItemViewModel[]) => {
    const gtmProducts: ProductWithQuantity[] = [];
    products.forEach((item) => {
        const {
            id,
            priceDetails,
            engrosColor,
            quantity,
            category,
            productId,
            brand,
            imageTags,
        } = item;
        const trackingName = `${brand} ${category} ${engrosColor}`;
        gtmProducts.push({
            id: productId,
            name: trackingName,
            category,
            brand,
            variant: engrosColor,
            quantity: quantity,
            price: priceDetails?.googleDisplayPrice,
            dimension11: id,
            ...getImageTags(imageTags),
        });
    });

    return gtmProducts;
};
