import { useEffect, useState } from 'react';
import { signOut, useSession } from 'next-auth/react';

/**
 * Extension to next auth use session hook
 * will redirect active session if refresh token is non available
 */
export const useAuthentication = () => {
    const { data: session } = useSession();
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isAuthenticating, setIsAuthenticating] = useState(true);

    useEffect(() => {
        if (session?.error === 'RefreshAccessTokenError') {
            signOut();
        }

        // if session is available the user is authenticated
        setIsAuthenticated(session !== null && session !== undefined);
    }, [session]);

    useEffect(() => {
        if (session !== undefined) {
            setIsAuthenticating(false);
        }
    }, [session]);

    return { isAuthenticated, isAuthenticating };
};
