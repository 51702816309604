import { ConsentType } from '~/shared/components';
import { useNotification } from '~/shared/hooks';

/**
 * Exposes methods that live on the window, that CookieInformation has added
 */
export const useCookieInformation = () => {
    const { push } = useNotification();
    const showMissing = () =>
        push({
            duration: 5000,
            text: 'CookieInformation does not exist on current environment',
        });
    const renewConsent = () => {
        if (window.CookieConsent?.renew) {
            window.CookieConsent.renew();
        } else {
            showMissing();
        }
    };
    const getConsentFor = (type: ConsentType) =>
        window.CookieInformation?.getConsentGivenFor?.(type);
    const loadConsent = () => window.CookieInformation?.loadConsent?.();
    /**
     * Updates culture of the script of cookie consent
     * @param culture ISO 639-1 but upper case (Ex: DA, EN). Invalid values will fall back to default set in CookieInformation website
     */
    const changeCookieInformationCulture = (culture: string) => {
        // Cookie consent script can't be removed to change language, but there is a helper method to reload
        // So it changes the culture attribute and calls that third party script method
        // https://support.cookieinformation.com/en/articles/5432277-single-page-applications-spa
        window.document.getElementById('CookieConsent')?.setAttribute('data-culture', culture);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        loadConsent();
    };

    // Split to only get language without region, so 'de-de' becomese just 'de'
    // CookieInformation expects it in capital case
    const getCookieInformationCulture = (culture?: string) =>
        (culture?.toLocaleUpperCase() || 'EN').split('-')[0];

    const getVisitorId = (): string | undefined => {
        return window.CookieInformation?._getVisitorId();
    };

    return {
        renewConsent,
        getConsentFor,
        changeCookieInformationCulture,
        getCookieInformationCulture,
        getVisitorId,
    };
};
