import { useTheme } from '@emotion/react';
import { AnimatePresence } from 'framer-motion';
import React from 'react';
import { StyledList } from '~/shared/components/Notifications/styled';
import { useNotification } from '~/shared/hooks/useNotification';
import { Notification } from './components/Notification';

/**
 * Displays UI based on notification state
 * can be used in root or a scoped location
 *
 */
export const Notifications = () => {
    const { notifications, dismiss } = useNotification();
    const { animations } = useTheme();

    return (
        <StyledList
            layout
            transition={{
                layout: {
                    duration: animations.durations.moderate01,
                    ease: animations.bezierValues.standard.productive,
                },
            }}
        >
            <AnimatePresence>
                {notifications.map((props) => (
                    <li key={props.id}>
                        <Notification onDismiss={() => dismiss(props.id)} {...props} />
                    </li>
                ))}
            </AnimatePresence>
        </StyledList>
    );
};
