import { CSSProperties } from 'react';

export const breaks = {
    /**
     * Mobile devices. Use orientation media queries for landscape styling
     */
    xs: 767,
    /**
     * Small tablets
     */
    sm: 768,
    /**
     * Large tablets, small laptops
     */
    md: 1024,
    /**
     * Desktops, laptops
     */
    lg: 1367,
    /**
     * Wide screens
     */
    xl: 1620,
};

export const queries = {
    xs: `(max-width: ${breaks.xs}px)`,
    sm: `(min-width: ${breaks.sm}px)`,
    md: `(min-width: ${breaks.md}px)`,
    lg: `(min-width: ${breaks.lg}px)`,
    xl: `(min-width: ${breaks.xl}px)`,
    reducedMotion: `(prefers-reduced-motion)`,
};

export const queriesIsolated = {
    xs: `(max-width: ${breaks.xs}px)`,
    sm: `(min-width: ${breaks.sm}px) and (max-width: ${breaks.md - 1}px)`,
    md: `(min-width: ${breaks.md}px) and (max-width: ${breaks.lg - 1}px)`,
    lg: `(min-width: ${breaks.lg}px) and (max-width: ${breaks.xl - 1}px)`,
    xl: `(min-width: ${breaks.xl}px)`,
};

export const breakpoints = {
    xs: `@media ${queries.xs}`,
    sm: `@media ${queries.sm}`,
    md: `@media ${queries.md}`,
    lg: `@media ${queries.lg}`,
    xl: `@media ${queries.xl}`,

    /**
     * Prefers reduced motion
     */
    reducedMotion: `@media ${queries.reducedMotion}`,
};

/**
 * Can be used for applying styles for each breakpoint
 */
export const onEachBreakpoint = (
    breakpointHandler: (
        breakpointKey: string,
        breakpointCondition: string
    ) => Record<string, CSSProperties>
) =>
    Object.entries(breakpoints).reduce((obj, [key, condition]) => {
        obj[condition] = breakpointHandler(key, condition);
        return obj;
    }, {} as { [key: string]: Record<string, CSSProperties> });
