import create from 'zustand';
import { BasketUIState } from '../models';

export const useBasketState = create<BasketUIState>((set) => ({
    isMiniBasketOpen: false,
    toggleMiniBasket: (isMiniBasketOpen) => {
        set(() => ({
            isMiniBasketOpen,
        }));
    },
}));
