import { Franchises } from '~/lib/data-contract';
import { runtimeConfig } from '~/shared/utils/public-variables';
import { Translations } from '~/shared/utils/translation';
import { fetcher } from './fetcher';
import { namespacedKeys } from '~/shared/utils/translation/utils/namespacedKeys';

const { API_URL } = runtimeConfig();
/**
 * Helper method for requesting translations for a particular culture and franchise.
 */
export async function fetchTranslations(
    locale: string,
    franchiseId: Franchises,
    params = {},
    headers?: HeadersInit,
    opts?: RequestInit
) {
    const response = await fetcher<Translations>(
        `${API_URL}/api/${franchiseId}/content/translations/${locale}`,
        { ...params },
        headers,
        opts
    );

    const translations = await response.json();

    const withNamedKeys = namespacedKeys(translations);

    return withNamedKeys;
}
