import { FormattedAlgoliaProductType } from '~/features/productsList/types';
import { Product } from '~/lib/data-contract';
import { getImageTags } from './getImageTags';
import { GTMProduct } from '../model';

export const algoliaMapper = (products: Product[] | FormattedAlgoliaProductType[]) => {
    const gtmProducts: GTMProduct[] = [];
    products.forEach((item) => {
        const { productId, category, position, variant, trackingBrand, imageTags } = item;
        const trackingName = `${trackingBrand} ${category} ${variant}`;

        gtmProducts.push({
            id: productId,
            name: trackingName,
            category,
            brand: trackingBrand,
            variant,
            position,
            // When data is coming from algolia we wont know the correct
            // SKU id since it's based on size AND color, so in this case we set it as undefined
            dimension11: undefined,
            ...getImageTags(imageTags),
        });
    });

    return gtmProducts;
};
