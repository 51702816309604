import { FormattedAlgoliaProductType } from '~/features/productsList/types';
import {
    LineItemViewModel,
    OrderProductTrackingModel,
    Product,
    ProductViewModel,
} from '~/lib/data-contract';
import { GTMProduct } from '../model';
import { ProductWithQuantity } from '../model/ProductWithQuantity';
import { algoliaMapper } from './algoliaMapper';
import { lineItemViewModelMapper } from './lineItemViewModelMapper';
import { orderProductTrackingViewModelMapper } from './orderProductTrackingViewModelMapper';
import { productViewModelMapper } from './productViewModelMapper';

/**
 * Maps reference items to media and text items
 *
 * @param items Array of products
 * @returns Array of GTMProduct or ProductWithQuantity
 */

type ProductArray =
    | FormattedAlgoliaProductType[]
    | Product[]
    | OrderProductTrackingModel[]
    | ProductViewModel[]
    | LineItemViewModel[];

export const gtmProductMapper = (items: ProductArray): GTMProduct[] | ProductWithQuantity[] => {
    if (!items?.length) {
        return [];
    }

    if (isAlgoliaProduct(items)) {
        return algoliaMapper(items);
    }

    if (isOrderProductTrackingViewModel(items)) {
        return orderProductTrackingViewModelMapper(items);
    }

    if (isLineItemViewModel(items)) {
        return lineItemViewModelMapper(items);
    }

    if (isProductViewModel(items)) {
        return productViewModelMapper(items);
    }

    return [];
};

export const isAlgoliaProduct = (items: ProductArray): items is Product[] => {
    return 'position' in items[0];
};

export const isOrderProductTrackingViewModel = (
    items: ProductArray
): items is OrderProductTrackingModel[] => {
    return 'dimension11' in items[0];
};

export const isLineItemViewModel = (items: ProductArray): items is LineItemViewModel[] => {
    return 'quantity' in items[0];
};

export const isProductViewModel = (items: ProductArray): items is ProductViewModel[] => {
    return 'sizes' in items[0];
};
