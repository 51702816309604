import create from 'zustand';
import { CookieConsentState } from './useCookieConsentModel';

/**
 * Hook that provides cookie consent state of different kinds.
 *
 * Usage example:
 *
 *  const { marketing } = useCookieConsent();
 *  ...
 *  { marketing && <ComponentThatRequiresMarketingConsent /> }
 */

export const useCookieConsent = create<CookieConsentState>((set) => ({
    setConsent: (settings) => {
        set(settings);
    },
}));

/**
 * How to hook it up:
 *
 * Depends, on your cookie consent management platform.
 * Regardless, when consent is given, you take that, map it into an CookieConsentSettings object, and use the setConsent of the useCookieConsent hook.
 *
 * Example: if your provider is Cookiebot (https://www.cookiebot.com):
 * 1. Utilize the CookiebotOnConsentReady event(on window). (The event is triggered when the user's consent state is ready, either from being submitted or loaded from an existing cookie)
 * The event object returns a Cookiebot.consent object, where in  marketing, necessary, preferences and statistics are represented with boolean values.
 * 2. Take the event values, and set them in the zustand state with setConsent of the useCookieConsent hook.
 * 3. Have a coffee, maybe a cookie with it...
 */
