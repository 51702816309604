import { useFranchise } from '~/shared/utils';
import { runtimeConfig } from '~/shared/utils/public-variables';

const { CUSTOMER_URL } = runtimeConfig();

export const useClubPaths = () => {
    const { franchiseAPIPath } = useFranchise();

    const apiUrl = `${CUSTOMER_URL}/${franchiseAPIPath}`;

    // Auth paths
    const signInUrl = `${apiUrl}/auth/login`;
    const softSignInUrl = `${apiUrl}/auth/softlogin`;
    const updatePasswordUrl = `${apiUrl}/auth/changepassword`;
    const resetPasswordUrl = `${apiUrl}/auth/forgotPassword/`;
    const recoverPasswordUrl = `${apiUrl}/auth/resetPassword`;
    const verifyResetPasswordSecretUrl = `${apiUrl}/auth/verifyPasswordtoken/`;
    const reAuthenticationUrl = `${apiUrl}/reAuth/reauth`;

    // Clubmember paths
    const createUserUrl = `${apiUrl}/clubmember/create`;
    const getUserUrl = `${apiUrl}/clubmember/get`;
    const editUserUrl = `${apiUrl}/clubmember/update`;
    const deleteAccountUrl = `${apiUrl}/clubmember/delete`;
    const activateAccountUrl = `${apiUrl}/clubmember/activate`;
    const getExistingClubMemberUrl = `${apiUrl}/clubmember/getpreexistingclubmemberdata`;

    // Newsletter paths
    const getStoresUrl = `${apiUrl}/newsletter/getstores`;
    const updatePermissionsUrl = `${apiUrl}/newsletter/update`;

    return {
        createUserUrl,
        signInUrl,
        softSignInUrl,
        getUserUrl,
        editUserUrl,
        updatePermissionsUrl,
        updatePasswordUrl,
        deleteAccountUrl,
        getExistingClubMemberUrl,
        activateAccountUrl,
        resetPasswordUrl,
        recoverPasswordUrl,
        verifyResetPasswordSecretUrl,
        getStoresUrl,
        reAuthenticationUrl,
    };
};
