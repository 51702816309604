import { memo, useEffect } from 'react';
import { isSSR } from '$shared/utils';
import { useClub } from '$features/club';

// Customer wants to have this 'signup-trigger' hardcoded for now and not use as setting from umbraco
export const GlobalClickHandler = memo(() => {
    const { toggleSignUpActive } = useClub();

    const handleStart = (e: MouseEvent) => {
        if ((e.target as Element)?.id === 'signup-trigger') {
            toggleSignUpActive(true);
        }
    };

    useEffect(() => {
        if (!isSSR) {
            window.addEventListener('click', handleStart, { passive: true });

            return () => {
                window.removeEventListener('click', handleStart);
            };
        }
    });

    return <></>;
});
