import React, { useEffect } from 'react';
import {
    StyleContent,
    StyledTextContent,
    StyledCloseButton,
    StyledNotification,
    StyledNotificationIconWrapper,
    StyledNotificationTimer,
    StyledNotificationList,
} from '../styled';
import { NotificationOptions, Severity } from '~/shared/hooks/useNotification/useNotificationModel';
import Close from '$icons/close.svg';
import Attention from '$icons/attention.svg';
import Info from '$icons/info.svg';
import Check from '$icons/check.svg';
import { useTranslation } from '~/shared/utils/translation';
import { useTimeoutFn } from 'react-use';
import { Text } from '~/shared/components';
import { useAnimation } from 'framer-motion';
import { useTheme } from '@emotion/react';
import { ThemeType } from '~/theme';

type Props = NotificationOptions & {
    onDismiss: () => void;
};

const variants = ({ animations }: ThemeType) => ({
    initial: {
        opacity: 0,
    },
    visible: {
        opacity: 1,
        transition: {
            ease: animations.bezierValues.entrance.productive,
            duration: animations.durations.fast02,
        },
    },
    exit: {
        opacity: 0,
        scale: 0.9,
        transition: {
            ease: animations.bezierValues.exit.productive,
            opacity: {
                duration: animations.durations.moderate02,
            },
            scale: {
                duration: animations.durations.fast02,
            },
        },
    },
});

export const Notification = ({
    text,
    headline,
    onDismiss,
    onClick,
    onDurationEnd,
    severity = 'error',
    duration,
}: Props) => {
    const { translate } = useTranslation();
    const [, cancel, restart] = useTimeoutFn(() => {
        onDismiss();
        onDurationEnd?.();
    }, duration);
    const theme = useTheme();
    const controls = useAnimation();

    const handleRestart = () => {
        restart();
        controls.start('timer');
    };

    const handleCancel = () => {
        cancel();
        controls.start({ scaleX: 1 });
    };

    const handleDismiss = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        onDismiss();
    };

    useEffect(() => {
        controls.start('timer');
    }, [controls]);

    useEffect(() => {
        if (!duration) {
            cancel();
        }
    }, [duration, cancel]);

    return (
        <StyledNotification
            layout
            transition={{
                layout: {
                    duration: theme.animations.durations.moderate01,
                    ease: theme.animations.bezierValues.exit.productive,
                },
            }}
            variants={variants(theme)}
            initial="initial"
            animate="visible"
            exit="exit"
            aria-live="polite"
            role="button"
            tabIndex={0}
            onClick={onClick || onDismiss}
            onFocus={handleCancel}
            onMouseEnter={handleCancel}
            onMouseLeave={duration ? handleRestart : undefined}
            onTouchStart={handleCancel}
            onTouchEnd={duration ? handleRestart : undefined}
        >
            <StyleContent>
                <StyledNotificationIconWrapper severity={severity}>
                    <NotificationIcon severity={severity} />
                </StyledNotificationIconWrapper>
                <StyledTextContent as={Array.isArray(text) ? 'div' : 'p'}>
                    <span>{headline}</span>
                    {Array.isArray(text) ? (
                        <StyledNotificationList>
                            {text.map((textItem, index) => (
                                <li key={index}>
                                    <Text variant="caption" as="span">
                                        {textItem}
                                    </Text>
                                </li>
                            ))}
                        </StyledNotificationList>
                    ) : (
                        <Text variant="caption" as="span">
                            {text}
                        </Text>
                    )}
                </StyledTextContent>
                <StyledCloseButton
                    children={<Close title={translate('general.close')} />}
                    onClick={handleDismiss}
                />
            </StyleContent>
            <StyledNotificationTimer
                severity={severity}
                variants={{
                    timer: {
                        scaleX: 0,
                        transition: {
                            duration: duration ? duration / 1000 : Infinity,
                            ease: 'linear',
                        },
                    },
                }}
                exit={{ transition: { duration: 0 } }}
                animate={controls}
                aria-hidden="true"
            />
        </StyledNotification>
    );
};

const NotificationIcon = ({ severity }: { severity: Severity }) => {
    switch (severity) {
        case 'info':
        case 'warning':
            return <Info />;
        case 'success':
            return <Check />;
        case 'error':
            return <Attention />;
    }
};
