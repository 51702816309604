import { ClubMemberViewModel, CreateClubMemberArgs } from '~/lib/data-contract';
import { postRequest } from '~/shared/utils';

export async function createUser(payload: CreateClubMemberArgs, apiUrl: string) {
    const request = await postRequest<CreateClubMemberArgs, ClubMemberViewModel>(apiUrl, payload);

    const response = await request();

    return response;
}
