import React, { memo, useEffect } from 'react';
import { useFrame } from '~/shared/utils/frame';
import Script from 'next/script';
import { useUpdateEffect } from 'react-use';
import { useCookieConsent } from '~/shared/hooks/useCookieConsent';
import { useCookieInformation } from './hooks/useCookieInformation';
import { useIsBrowserBot, useNotification } from '~/shared/hooks';
import { isLocalHostname, isSSR } from '~/shared/utils';

export type ConsentType = 'cookie_cat_functional' | 'cookie_cat_statistic' | 'cookie_cat_marketing';

/**
 * Loads cookie consent, and reloads in the correct language if culture changes
 */
export const CookieConsent = memo(() => {
    const { data } = useFrame();
    const isBrowserBot = useIsBrowserBot();
    const { setConsent } = useCookieConsent();
    const {
        getConsentFor,
        changeCookieInformationCulture,
        getCookieInformationCulture,
    } = useCookieInformation();
    const { push } = useNotification();
    const culture = getCookieInformationCulture(data?.culture);
    // Right now we always reload the entire site when changing language,
    // but this will handle change without reload, if optimizing UX down the road
    useUpdateEffect(() => {
        changeCookieInformationCulture(culture);
    }, [culture]);

    useEffect(() => {
        const handleConsentUpdate = () => {
            setConsent({
                functional: getConsentFor('cookie_cat_functional'),
                statistic: getConsentFor('cookie_cat_statistic'),
                marketing: getConsentFor('cookie_cat_marketing'),
            });
        };
        window.addEventListener('CookieInformationConsentGiven', handleConsentUpdate);
        return () => {
            window.removeEventListener('CookieInformationConsentGiven', handleConsentUpdate);
        };
    }, [setConsent, getConsentFor]);

    const handleCookieInformationScriptError = (error?: Error) => {
        console.error(error);
        push({
            headline: error?.message,
        });
    };

    if (!isSSR) {
        if (isLocalHostname || isBrowserBot) {
            return null;
        }
    }

    return (
        <Script
            onError={handleCookieInformationScriptError}
            id="CookieConsent"
            src="https://policy.app.cookieinformation.com/uc.js"
            data-culture={
                culture
            } /** Note that changing the prop will not automatically reload the script */
            data-gcm-version="2.0"
            type="text/javascript"
        />
    );
});
