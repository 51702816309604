import { getRequest, errorToJson } from '$shared/utils';
import { BasketViewModel } from '~/lib/data-contract';
import { useBasketState } from './useBasketState';
import { useBasketPaths } from './useBasketPaths';
import { useQuery, useQueryClient } from 'react-query';
import { useNotification } from '~/shared/hooks/useNotification';
import { useMemo } from 'react';
import { usePage } from '~/templates/pages';
import { useBasketReference } from './useBasketReference';

/**
 * Exposes basket data and actions.
 */
export const useBasket = () => {
    const queryClient = useQueryClient();

    const uIState = useBasketState();
    const { basketUrl, basketQueryKey } = useBasketPaths();
    const { push } = useNotification();
    const { basketId, set, remove } = useBasketReference();
    const { type } = usePage();
    const isReceipt = type === 'p110receiptPage';

    const {
        data: basket,
        error,
        isLoading,
        isError,
        isStale,
        isFetched,
        status,
        refetch,
    } = useQuery<BasketViewModel, Response>(
        basketQueryKey,
        getRequest<BasketViewModel>(basketUrl),
        {
            // Avoid requesting basket on receipt page, this will potientially cause a cancelled order
            enabled: Boolean(basketId && !isReceipt),
            // Set basket to stale after  5 minutes
            staleTime: 5 * 60 * 1000, // 5 minutes
            // Make sure we refetch basket always on focus
            refetchOnWindowFocus: 'always',
            retry: (failureCount, error) => {
                if (error instanceof Response && error?.status === 404) {
                    remove();
                    queryClient.removeQueries(basketQueryKey, { exact: true });

                    // TODO: Basket gone figure out what to show to the user
                    return false;
                }

                return failureCount < 3;
            },
            onError: async (error) => {
                const { level, errorMessages } = await errorToJson(error);
                push({ severity: level, text: errorMessages });
            },
            onSuccess: (basket) => {
                if (basket.id && (!basketId || basketId !== basket.id)) {
                    set(basket.id);
                }
            },
        }
    );

    const totalQuantity = useMemo(
        () => basket?.lines?.reduce((acc, { quantity = 1 }) => acc + quantity, 0),
        [basket?.lines]
    );

    return {
        ...uIState,
        data: basket,
        totalQuantity,
        status,
        error,
        refetch,
        isLoading,
        isError,
        isStale,
        isFetched,
    };
};
