import { fetcher } from './fetcher';

/**
 * Simpler version of a POST request, supplies content type and serializes body to json
 */
export const postRequest = <ResponseBody = unknown, RequestBody = unknown>(
    url: string,
    body?: RequestBody,
    headers: HeadersInit = {}
) => {
    const opts: RequestInit = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json; charset=utf-8', ...headers },
    };

    if (body) {
        opts['body'] = JSON.stringify(body);
    }

    return fetcher<ResponseBody>(url, opts);
};
