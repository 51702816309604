import { signOut, useSession } from 'next-auth/react';
import { useGetRequest, useTranslation } from '~/shared/utils';
import { useClubPaths } from '../hooks/useClubPaths';
import {
    ChangePasswordArgs,
    ClubMemberViewModel,
    UpdateClubMembersArgs,
    UpdateNewsletterArgs,
} from '~/lib/data-contract';
import { useEffect } from 'react';
import { useMutation } from 'react-query';
import { useNotification } from '~/shared/hooks';
import {
    editUser,
    editPermissions,
    editPassword,
    deleteUserAccount,
    resetUserPassword,
    verifyResetPasswordSecret,
} from '~/features/club/service';

export const useAccount = () => {
    const { data: session } = useSession();
    const { push } = useNotification();
    const { translate } = useTranslation();
    const {
        getUserUrl,
        editUserUrl,
        updatePermissionsUrl,
        updatePasswordUrl,
        deleteAccountUrl,
        resetPasswordUrl,
        verifyResetPasswordSecretUrl,
    } = useClubPaths();

    const headers = session?.accessToken
        ? { Authorization: `Bearer ${session.accessToken}` }
        : undefined;

    const {
        data: account,
        remove,
        invalidate: invalidateAccount,
        ...rest
    } = useGetRequest<ClubMemberViewModel>(getUserUrl, {
        headers,
        enabled: Boolean(session?.accessToken),
    });

    const {
        /**
         * Update account data
         */
        mutateAsync: updateUser,
        isLoading: updateIsLoading,
        isSuccess: updateIsSuccess,
        data: updateData,
    } = useMutation<ClubMemberViewModel, Response, UpdateClubMembersArgs>(
        (args) => editUser(args, editUserUrl, headers),
        {
            onSuccess: async () => {
                invalidateAccount();
                push({ severity: 'success' });
            },
        }
    );

    const {
        /**
         * Update newsletter permissions
         */
        mutateAsync: updatePermissions,
        isLoading: updatePermissionsIsLoading,
        isSuccess: updatePermissionsIsSuccess,
    } = useMutation<void, Response, UpdateNewsletterArgs>(
        (args) => editPermissions(args, updatePermissionsUrl, headers),
        {
            onSuccess: async () => {
                invalidateAccount();
            },
        }
    );

    const {
        /**
         * Update password
         */
        mutateAsync: updatePassword,
        isLoading: updatePasswordIsLoading,
        isSuccess: updatePasswordIsSuccess,
        data: updatePasswordData,
    } = useMutation<boolean, Response, ChangePasswordArgs>(
        (args) => editPassword(args, updatePasswordUrl, headers),
        {
            onSuccess: async () => {
                invalidateAccount();
                push({ severity: 'success' });
            },
        }
    );

    const {
        /**
         * Reset password
         */
        mutateAsync: resetPassword,
        isLoading: resetPasswordIsLoading,
        isSuccess: resetPasswordIsSuccess,
        data: resetPasswordData,
    } = useMutation<boolean, Response, { email?: string }>(
        ({ email }) => resetUserPassword(`${resetPasswordUrl}/${email}`, headers),
        {
            onSuccess: async () => {
                push({ severity: 'success', text: translate('club.resetPassword.successMessage') });
            },
        }
    );

    const {
        /**
         * Delete account
         */
        mutateAsync: deleteAccount,
        isLoading: deleteAccountIsLoading,
        isSuccess: deleteAccountIsSuccess,
    } = useMutation<void, Response, void>(() => deleteUserAccount(deleteAccountUrl, headers), {
        onSuccess: async () => {
            const duration = 7000;
            push({
                severity: 'success',
                text: translate('club.deleteAccount.notificationText'),
                duration,
                onClick: () => signOut(),
            });
            setTimeout(() => {
                signOut();
            }, duration);
        },
    });

    const {
        /**
         * Verify reset password token
         */
        mutateAsync: verifyResetPassword,
        isLoading: verifyResetPasswordIsLoading,
        isSuccess: verifyResetPasswordIsSuccess,
        isError: verifyResetPasswordIsError,
    } = useMutation<boolean, Response, { token: string }>(({ token }) =>
        verifyResetPasswordSecret(`${verifyResetPasswordSecretUrl}${token}`, headers)
    );

    useEffect(() => {
        if (!session) {
            remove();
        }
    }, [session, remove]);

    return {
        account,
        invalidateAccount,
        updateIsLoading,
        updateUser,
        updateIsSuccess,
        updateData,
        updatePermissions,
        updatePermissionsIsLoading,
        updatePermissionsIsSuccess,
        updatePassword,
        updatePasswordIsLoading,
        updatePasswordIsSuccess,
        updatePasswordData,
        deleteAccount,
        deleteAccountIsLoading,
        deleteAccountIsSuccess,
        resetPassword,
        resetPasswordIsLoading,
        resetPasswordIsSuccess,
        resetPasswordData,
        verifyResetPassword,
        verifyResetPasswordIsLoading,
        verifyResetPasswordIsSuccess,
        verifyResetPasswordIsError,
        authHeaders: headers,
        ...rest,
    };
};
