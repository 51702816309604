import { VisuallyHidden } from '@radix-ui/react-visually-hidden';
import Head from 'next/head';
import React, { memo } from 'react';
import { HrefLangViewModel } from '~/lib/data-contract';
import { useProduct } from '~/shared/utils/product';
import { usePage } from '~/templates/pages';
import { useRouter } from 'next/router';
import { useLocation } from 'react-use';
import { useFrame } from '$shared/utils';

export const MetaFields = memo(({ pageBaseUrl }: { pageBaseUrl?: string }) => {
    const { ogImage, meta, hiddenH1, hrefLangs: contentHreflangs, url, type } = usePage();
    const { data: frame } = useFrame();
    const { data: product } = useProduct();
    const { metaTitle, metaDescription, canonicalUrl, hideFromGoogle } = meta || {};
    const { asPath, locale } = useRouter();
    const { pathname } = useLocation();

    const hrefLangs = product?.hrefLangs ? product.hrefLangs : contentHreflangs;
    let canonical = product?.canonical || canonicalUrl?.url || url;

    const pageMetaTitle = metaTitle || product?.metaTitle || null;
    const pageMetaDescription = metaDescription || product?.metaDescription || null;
    const image = ogImage?.src || frame?.settings?.trackingSetup?.socialImage?.src;

    if (type === 'p50ProductDetailPage') {
        if (pathname && locale) {
            canonical = pathname.includes(locale)
                ? `${pageBaseUrl}/${locale}${asPath}`
                : `${pageBaseUrl}${asPath}`;
        } else {
            canonical = `${pageBaseUrl}/${locale}${asPath}`;
        }
    }

    return (
        <>
            <Head>
                <meta charSet="utf-8" />
                <meta name="viewport" content="initial-scale=1.0, width=device-width" />
                {pageMetaTitle ? <title>{pageMetaTitle}</title> : null}
                {pageMetaDescription ? (
                    <meta name="description" content={pageMetaDescription} />
                ) : null}
                {image && !product && <meta name="image" content={image} />}

                {hideFromGoogle ? <meta name="robots" content="noindex" /> : null}
                {canonical ? <link key="canonical" rel="canonical" href={canonical} /> : null}

                {hrefLangs?.map(({ culture, url }: HrefLangViewModel) => (
                    <link
                        rel="alternate"
                        key={`hreflang-${culture}`}
                        hrefLang={culture}
                        href={url}
                    />
                ))}
            </Head>
            {hiddenH1 ? (
                <VisuallyHidden>
                    <h1>{hiddenH1}</h1>
                </VisuallyHidden>
            ) : null}
        </>
    );
});
