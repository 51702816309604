import { useMemo } from 'react';
import { useFrame, useFranchise } from '~/shared/utils';
import { useProduct } from '~/shared/utils/product';
import { usePage } from '~/templates/pages';

// Gets schema from CMS
export const useSchemaMarkup = () => {
    const { activeMarket, host } = useFranchise();
    const page = usePage();
    const { meta } = page;
    const { data: product } = useProduct();
    const { data: frame } = useFrame();

    const markup = meta?.markup;
    const logo = frame?.settings?.trackingSetup?.siteLogo?.src;

    const productSchema = useMemo(() => {
        if (!product) {
            return null;
        }

        const {
            productId,
            name,
            canonical,
            images,
            brand,
            metaDescription,
            sizes,
            masterCategory,
            priceDetails,
        } = product;
        const inStock = sizes?.some((size) => size.inStockOnWeb);

        return JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'Product',
            productId,
            name,
            url: canonical,
            image: images?.[0]?.url,
            brand: {
                '@type': 'Brand',
                name: brand,
            },
            category: masterCategory,
            description: metaDescription,
            offers: {
                '@type': 'Offer',
                availability: inStock ? 'InStock' : 'NoStock',
                price: priceDetails?.rawSalePrice,
                priceCurrency: activeMarket?.currency,
            },
        });
    }, [product, activeMarket?.currency]);

    const pageMarkup = useMemo(() => {
        if (!markup) {
            return null;
        }

        return markup;
    }, [markup]);

    const logoMarkup = useMemo(() => {
        if (!logo || !host) {
            return null;
        }

        return JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'Organization',
            url: `https://www.${host}`,
            logo: `${logo}`,
        });
    }, [logo, host]);

    return {
        productSchema,
        pageMarkup,
        logoMarkup,
    };
};
