import { readableColorIsBlack } from 'color2k';
import { TextShade } from '$shared/components';

export const getTextShade = (
    backgroundColor?: string,
    defaultTextShade: Exclude<TextShade, 'paragraphInverted' | 'headlineInverted'> = 'paragraph'
) => {
    if (!backgroundColor || ['transparent', 'none'].includes(backgroundColor)) {
        return defaultTextShade;
    }

    // Check contrast
    return readableColorIsBlack(backgroundColor)
        ? defaultTextShade
        : (`${defaultTextShade}Inverted` as TextShade);
};
