import { CSSObject } from '@emotion/react';

/**
 * Stretches element to container
 * make sure to add 'relative' to parent
 */
export const cover: CSSObject = {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
};
