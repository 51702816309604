import { P180StorePage as P180StorePageType } from '~/lib/data-contract';
import { useFranchise } from '~/shared/utils';
import { usePage } from '~/templates/pages';

// Create schema for P180 store page
export const useSchemaMarkupStore = () => {
    const page = usePage<P180StorePageType>();
    const { activeMarket } = useFranchise();

    const {
        storeImage,
        storeName,
        city,
        zipcode,
        address,
        phone,
        storeId,
        email,
        openingHoursJson,
        facebookLink,
        url,
        latitude,
        longitude,
        type,
    } = page;

    if (type !== 'p180StorePage') {
        return;
    }

    return JSON.stringify({
        '@context': 'https://schema.org',
        '@type': ['MensClothingStore', 'ClothingStore'],
        name: storeName,
        url,
        sameAs: facebookLink || '',
        image: storeImage?.src,
        priceRange: '$$',
        paymentAccepted: 'Cash, Credit Card',
        telephone: phone,
        email,
        branchCode: storeId,
        address: {
            '@type': 'PostalAddress',
            streetAddress: address,
            addressLocality: city,
            postalCode: zipcode,
            addressCountry: activeMarket?.countryName,
        },
        geo: {
            '@type': 'GeoCoordinates',
            latitude,
            longitude,
        },
        openingHoursSpecification: openingHoursJson?.map((o) => ({
            '@type': 'OpeningHoursSpecification',
            dayOfWeek: [
                'Monday',
                'Tuesday',
                'Wednesday',
                'Thursday',
                'Friday',
                'Saturday',
                'Sunday',
            ][o.weekDay || 0],
            opens: o.openingTime,
            closes: o.closingTime,
        })),
    });
};
