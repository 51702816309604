import { Refinement } from 'react-instantsearch-core';
import { Franchise } from '~/templates/pages';
import { AlgoliaProductType, FormattedAlgoliaProductType } from './types';

/**
 * Algolia Formatter
 *
 * @param item - product object in algolia structure
 * @returns product object in generalized product card structure.
 */
export const mapFromAlgolia = (
    item: AlgoliaProductType,
    franchise: Franchise,
    position: number
): FormattedAlgoliaProductType => {
    const {
        images,
        displayPrice,
        beforePrice,
        displayBeforePrice,
        name,
        subtitle,
        productColorCount,
        variants,
        productId,
        productUrl,
        objectID,
        brand,
        imageTags,
    } = item;

    const mappedImages = [];

    // Default Image
    if (images.length) {
        mappedImages.push({
            url: images[0].url,
            label: images[0].label,
        });

        // Hover Image
        if (images.length > 1) {
            mappedImages.push({
                url: images[1].url,
                label: images[1].label,
            });
        }
    }

    const currency = franchise.activeMarket?.displayCurrency || '';
    const attributes = variants?.[0].attributes;
    const category = attributes?.ProductType2 || attributes?.ProductType1;
    const variant = attributes?.EngrosColor;
    const color = attributes?.MotherColour;

    // To make sure the position is the same across
    // we start the position from 0, Algolia uses position which starts at 1
    const positionAsArrayPosition = position - 1;

    return {
        name,
        subtitle,
        colors: productColorCount,
        id: variants?.[0]?.sku || '',
        category,
        position: positionAsArrayPosition,
        images: mappedImages,
        trackingBrand: brand,
        productId,
        objectId: objectID,
        productUrl,
        variant,
        color,
        imageTags,
        priceDetails: {
            displaySalePrice: `${displayBeforePrice ? displayPrice : ''} ${currency}`,
            displayUnitPrice: `${displayBeforePrice || displayPrice} ${currency}`,
            isOnSale: !!beforePrice,
            currency,
            showSavings: false,
            savings: '',
        },
    };
};

/**
 * Util function to escape certain characters
 * currently we are aware of single quotes
 */
export const getEscapedKey = (key?: string) =>
    key && typeof key === 'string' ? key.replace(/'/g, "\\'") : key;

/**
 * Util to remove dublicate attributes from a refinement list
 * can be used in coherence to Algolia since using multiple refinementList
 * Would result in dublicates
 * @param items
 */
export const removeAttributeDuplicates = (items: Refinement[]) => {
    const collection: Record<string, Refinement> = {};
    items.forEach((item) => {
        if (item.attribute) {
            collection[item.attribute] = item;
        }
    });

    return Object.values(collection);
};
