import { useFranchise } from '~/shared/utils';
import { useRouter } from 'next/router';
import { fetchTranslations } from '~/lib/fetchTranslations';
import { useQuery } from 'react-query';
import { translateFactory } from '../utils/translateFactory';
import { Translations } from '../model/translationModel';
import { useMemo } from 'react';

/**
 * Translation hook, will load initial translations provided by hydrate on initial load.
 * Cache for 10 minutes, and refresh on window focus if stale.
 *
 * Use double curly brackets for a dynamic value in the translated text {{property}} and pass an object in the code with matching property name
 */
export const useTranslation = () => {
    const { locale = '' } = useRouter();
    const { key } = useFranchise();

    // Translations are shared between franchises, therefore locale is used as key
    const { data: translations, isLoading, isSuccess } = useQuery<Translations, Error>(
        ['translations', { queryKey: locale }],
        () => fetchTranslations(locale, key),
        {
            retry: false,
            staleTime: 10 * 60 * 1000, // 10 minutes
            refetchOnWindowFocus: true,
            keepPreviousData: true,
        }
    );

    const translate = useMemo(() => translateFactory(translations), [translations]);

    return { translate, isLoading, isSuccess };
};
