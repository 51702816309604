import { TranslationKey } from '~/lib/data-contract';
import { Translations } from '../model/translationModel';

/**
 * Provides a translations function that uses the keys to get the value, and interpolate any dynamic values in it
 * Supply key/value pairs object from CMS
 * Handles pluralization by looking for -plural suffix on translation keys, will do it automatically if there's a single interpolation value
 * For pluralization on multiple interpolation values a pluralizationKey should be supplied
 *
 * Ex:
 *      const translate = translateFactory({ key: 'my {{interpolate}} value', 'key-plural': 'my {{interpolate}} values' });
 *      translate('key', { interpolate: 'dynamic' }) // "my dynamic value"
 *      translate('key', { interpolate: 5 }) // "my 5 values"
 *
 *      const translate = translateFactory({ 'product-in-category': 'Viewing {{quantity}} {{category}} product', 'product-in-category-plural': 'Viewing {{quantity}} {{category}} products' });
 *      translate('product-in-category', { quantity: 1, category: 'tableware' }, 'quantity') // "Viewing 1 tableware product"
 *      translate('product-in-category', { quantity: 3, category: 'tableware' }, 'quantity') // "Viewing 3 tableware products"
 */
const warnings: Record<TranslationKey, boolean> | Record<string, unknown> = {};
export const translateFactory = (data?: Translations) => (
    key: TranslationKey,
    interpolation?: Record<string, string | number>,
    pluralizationKey?: string
) => {
    let translation = data?.[key];

    if (translation === undefined) {
        if (!warnings[key] && process.env.VERBOSE_LOG === 'true') {
            console.info(`Missing translation: ${key}`);
            warnings[key] = true;
        }

        return key;
    }
    if (interpolation) {
        const entries = Object.entries(interpolation);
        // Check for pluralization
        const pluralTranslation = data?.[`${key}-plural` as never];
        if (pluralTranslation) {
            if (pluralizationKey) {
                const interpolationValue = interpolation[pluralizationKey];
                if (typeof interpolationValue === 'number' && interpolationValue !== 1) {
                    translation = pluralTranslation;
                }
            } else if (entries.length === 1) {
                const [, interpolationValue] = entries[0];
                if (typeof interpolationValue === 'number' && interpolationValue !== 1) {
                    translation = pluralTranslation;
                }
            }
        }
        entries.forEach(([key, value]) => {
            translation = translation?.replace(new RegExp(`{{${key}}}`, 'g'), String(value));
        });
    }
    return translation;
};
