import { QueryKey, useQuery, useQueryClient, UseQueryOptions, UseQueryResult } from 'react-query';
import { buildURL, QueryParams } from '../utils/buildURL';
import { fetcher } from '../utils/fetcher';

type Mutate<T> = (newValue: T, invalidateQuery: boolean) => void;
type Invalidate = () => void;

type UseGetRequestResult<T, E> = UseQueryResult<T, E> & {
    isValidating: boolean;
    mutate: Mutate<T>;
    invalidate: Invalidate;
};

/**
 * Helper method for useQuery (https://react-query.tanstack.com/reference/useQuery)
 *
 * Added:
 * - url + params are used as the key
 * - `mutation` helper method inspired by useSWR.
 * - `isValidating` helper value inspired by useSWR
 * - `invalidate` helper method to invalidate and refetch data
 *
 * Using React Query over useSWR to future proof the codebase.
 * Read all available benefits here:
 * NOTE: not all features are implemented yet.
 * @see https://react-query.tanstack.com/comparison
 */
export const useGetRequest = <T, E = unknown>(
    url: string,
    options?: UseQueryOptions<T, E, T, QueryKey> & { params?: QueryParams; headers?: HeadersInit }
): UseGetRequestResult<T, E> => {
    const { params, headers, ...otherOptions } = options || {};

    // Generate a determanistic URL based on url + params
    const serializedUrl = buildURL(url, params);
    const queryClient = useQueryClient();

    // Use determanistic generated URL as key
    const query = useQuery<T, E>(
        serializedUrl,
        fetcher<T>(serializedUrl, { headers }),
        { ...otherOptions }
    );

    const isValidating = query.isFetching;

    // Helper method for invalidating
    const invalidate: Invalidate = () => queryClient.invalidateQueries(serializedUrl);

    // Helper method for mutating the stored state
    const mutate: Mutate<T> = (newValue, invalidateQuery = false) => {
        queryClient.setQueryData(serializedUrl, () => newValue);
        if (invalidateQuery) {
            invalidate();
        }
    };

    return {
        ...query,
        isValidating,
        mutate,
        invalidate,
    };
};
