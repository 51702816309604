import { ComponentProps } from 'react';
import { StyledText } from './styled';

export const textVariants = [
    'display1',
    'display2',
    'display3',
    'display4',
    'display5',
    'display6',
    'body',
    'bodySm',
    'caption',
    'link',
    'quote',
    'overline',
] as const;

export const textShades = [
    'paragraph',
    'paragraphInverted',
    'headline',
    'headlineInverted',
] as const;

export type TextVariants = typeof textVariants[number];
export type TextShade = typeof textShades[number];

export type TextProps = ComponentProps<typeof StyledText>;

/**
 * Override rendered markup with `as` attribute. For instance:
 *
 * ```tsx
 * <Text as="span">An span in markup.</Text>
 * ```
 */

export const Text = StyledText;
