/**
 * Formats original index
 */
export const formatIndex = (originalIndex: string) => {
    if (originalIndex.startsWith('live')) {
        return `main-${originalIndex.replace('live-', 'live_')}`;
    }

    return originalIndex;
};
