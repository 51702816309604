import { BASKET_STORAGE_KEY } from '~/features/basket/constants/keys';
import { writeStorage, deleteFromStorage, useLocalStorage } from '@rehooks/local-storage';
import { useCallback } from 'react';

export const useBasketReference = () => {
    const [basketId] = useLocalStorage<string | null>(BASKET_STORAGE_KEY, null);

    const set = useCallback((basketId: string) => {
        writeStorage(BASKET_STORAGE_KEY, basketId);
    }, []);

    const remove = useCallback(() => {
        deleteFromStorage(BASKET_STORAGE_KEY);
    }, []);

    return { basketId, set, remove };
};
