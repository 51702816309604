import { useContext } from 'react';
import { GtmContext } from '~/shared/utils/gtm/context/GtmContext';

/**
 * Exposes the Google Tag Manager API to the application.
 *
 */
export const useGtm = () => {
    return useContext(GtmContext);
};
