import styled from '@emotion/styled';
import { m } from 'framer-motion';
import { ifProp } from 'styled-tools';
import { Text } from '~/shared/components';

type StyledStrokeTextProps = {
    hasText: boolean;
    strokeHeight: string | number;
    strokeColor: string;
};

export const StyledStrokeText = styled(m(Text), {
    shouldForwardProp: (prop) =>
        !['hasText', 'strokeHeight', 'strokeColor'].includes(prop.toString()),
})<StyledStrokeTextProps>(
    ({ strokeHeight, strokeColor }) => ({
        height: strokeHeight,
        backgroundImage: `linear-gradient(
            transparent calc(100% - ${strokeHeight}${
            isNaN(Number(strokeHeight)) ? '' : 'px'
        }), ${strokeColor} 0px)`,
        backgroundRepeat: 'no-repeat',
        textDecoration: 'none',
        borderRadius: 'inherit',
    }),
    ifProp({ hasText: false }, () => ({
        position: 'absolute',
        inset: 'auto 0 0 0',
        width: '100%',
    }))
);
