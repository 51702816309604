import { ChangePasswordArgs } from '~/lib/data-contract';
import { postRequest } from '~/shared/utils';

export async function editPassword(
    payload: ChangePasswordArgs,
    apiUrl: string,
    headers?: HeadersInit
) {
    const request = await postRequest<boolean, ChangePasswordArgs>(apiUrl, payload, headers);

    const response = await request();

    return response;
}
