/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * omit function returns a copy of the original object without the keys provied in KeyToOmit
 *
 *  Params
 *
 *  obj - Object
 *  Object from where to remove key
 *
 *  keysToOmit - Array
 *  List of keys to omit from original object
 */
export const omit = (obj: any, keysToOmit: string[]) => {
    return Object.keys(obj).reduce((result: any, key: any) => {
        if (!keysToOmit.includes(key)) {
            result[key] = obj[key];
        }
        return result;
    }, {});
};

/**
 * isObject function validates if value passed has type object and its not null
 *
 *  Params
 *
 *  obj - Object
 *  value to validate type
 */
export const isObject = (obj: any) => {
    return typeof obj == 'object' && obj != null;
};

/**
 * deepEqual function compares two values and calls itself if keys have comples type
 *
 *  Params
 *
 *  x - Object
 *  value to compare
 *
 *  y - Object
 *  value to compare
 */
export const deepEqual = (x: any, y: any) => {
    if (x === y) {
        return true;
    } else if (isObject(x) && isObject(y)) {
        // Check if has the same number of keys
        if (Object.keys(x).length != Object.keys(y).length) {
            return false;
        }

        for (const prop in x) {
            // foreach key in both objt compare
            // if key not present in one object return false (different object)
            if (
                !Object.prototype.hasOwnProperty.call(y, prop) ||
                (Object.prototype.hasOwnProperty.call(y, prop) && !deepEqual(x[prop], y[prop]))
            ) {
                return false;
            }
        }

        return true;
    } else return false;
};

/**
 * Remove top level empty values from object
 *
 * @param obj
 * @returns
 */
export const removeEmpty = <T extends { [s: string]: unknown }>(obj: T): Partial<T> =>
    Object.fromEntries(
        Object.entries(obj).filter(([_, v]) => v || v === 0 || v === false)
    ) as Partial<T>;

/**
 * Rotates array, so e.g. rotateArray(arr = [1, 2, 3, 4, 5], k = 2) will return [3, 4, 5, 1, 2]
 * The rotation removes elements from the start of the array, and appends to the end.
 *
 * @param arr An array of arbitrary entries
 * @param k The number of positions to rotate.
 * @returns array
 */
export const rotateArray = <T>(arr: Array<T>, k: number) =>
    arr.slice(k % arr.length).concat(arr.slice(0, k % arr.length));

/**
 * Returns the nearest scrollable parent element
 *
 * @param element HTMLElement of which to find the nearest scroll parent
 * @returns HTMLElement | void
 */
export const findScrollContainer = (element?: HTMLElement): HTMLElement | void => {
    if (!element) {
        return;
    }
    let parent = element.parentElement;
    while (parent) {
        const { overflow } = window.getComputedStyle(parent);
        if (overflow.split(' ').every((o) => o === 'auto' || o === 'scroll')) {
            return parent;
        }
        parent = parent.parentElement;
    }

    return document.documentElement;
};

/**
 * Debouncs function calls
 *
 * @param callback the method to invoke once the waittime has expired
 * @param delay debounce time
 */
export const debounce = <T extends (...args: any[]) => any>(callback: T, delay: number) => {
    let timeout: ReturnType<typeof setTimeout>;
    return (...args: Parameters<T>) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => callback(...args), delay);
    };
};
