import create from 'zustand';

type ClubUIState = {
    signUpActive: boolean;
    toggleSignUpActive: (signUpActive: boolean) => void;
    signUpActiveKeepScroll: boolean;
    toggleSignUpActiveKeepScroll: (signUpActive: boolean) => void;
    signInActive: boolean;
    toggleSignInActive: (signInActive: boolean) => void;
    editAccountActive: boolean;
    toggleEditAccountActive: (editAccountActive: boolean) => void;
    editAccountStoreActive: boolean;
    toggleEditAccountStoreActive: (editAccountStoreActive: boolean) => void;
    editPasswordActive: boolean;
    toggleEditPasswordActive: (editPasswordActive: boolean) => void;
    deleteAccountActive: boolean;
    toggleDeleteAccountActive: (deleteAccountActive: boolean) => void;
    resetPasswordActive: boolean;
    toggleResetPasswordActive: (resetPasswordActive: boolean) => void;
    activateUserActive: boolean;
    toggleActivateUserActive: (activateUserActive: boolean) => void;
};

export const useClubState = create<ClubUIState>((set) => ({
    signUpActive: false,
    signUpActiveKeepScroll: false,
    signInActive: false,
    editAccountActive: false,
    editAccountStoreActive: false,
    editPasswordActive: false,
    deleteAccountActive: false,
    resetPasswordActive: false,
    activateUserActive: false,
    toggleSignUpActive: (signUpActive) => {
        set(() => ({
            signUpActive,
        }));
    },
    toggleSignUpActiveKeepScroll: (signUpActiveKeepScroll) => {
        set(() => ({
            signUpActiveKeepScroll,
        }));
    },
    toggleSignInActive: (signInActive) => {
        set(() => ({
            signInActive,
        }));
    },
    toggleEditAccountActive: (editAccountActive) => {
        set(() => ({
            editAccountActive,
        }));
    },
    toggleEditAccountStoreActive: (editAccountStoreActive) => {
        set(() => ({
            editAccountStoreActive,
        }));
    },
    toggleEditPasswordActive: (editPasswordActive) => {
        set(() => ({
            editPasswordActive,
        }));
    },
    toggleDeleteAccountActive: (deleteAccountActive) => {
        set(() => ({
            deleteAccountActive,
        }));
    },
    toggleResetPasswordActive: (resetPasswordActive) => {
        set(() => ({
            resetPasswordActive,
        }));
    },
    toggleActivateUserActive: (activateUserActive) => {
        set(() => ({
            activateUserActive,
        }));
    },
}));
