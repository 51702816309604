import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { CSSProperties, useMemo, useState } from 'react';
import { breakpoints, onEachBreakpoint } from '~/theme';
import { LayoutOverlayButton } from '../LayoutOverlayButton/LayoutOverlayButton';
import { useEffectOnce } from 'react-use';

type LayoutOverlayProps = { visibility: CSSProperties['visibility'] };

const StyledLayoutOverlay = styled.div<LayoutOverlayProps>(({ theme, visibility }) => ({
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 9999990,
    display: 'grid',
    pointerEvents: 'none',
    visibility,
    overflow: 'hidden',
    gridTemplateColumns: `${theme.gridConfig.gutter} 1fr ${theme.gridConfig.gutter}`,
}));

const StyledLayoutColumns = styled.div(({ theme }) => ({
    position: 'relative',
    display: 'grid',
    alignItems: 'stretch',
    gridTemplateColumns: `repeat(${theme.gridConfig.columnCount.xs}, 1fr)`,
    gridColumnStart: 2,
    gap: theme.gridConfig.gap,
    overflow: 'hidden',
    [breakpoints.md]: {
        gridTemplateColumns: `repeat(${theme.gridConfig.columnCount.md}, 1fr)`,
    },
}));

const StyledLayoutColumn = styled.div<{ index: number }>(({ theme, index }) => ({
    position: 'relative',
    display: index < theme.gridConfig.columnCount.xs ? 'block' : 'none',
    backgroundColor: 'rgba(170, 225, 255, 0.2)',

    [breakpoints.md]: {
        display: 'block',
    },
}));

const StyledLayoutBreakpointLabel = styled.div({
    position: 'absolute',
    bottom: 0,
    left: 0,
    padding: '5px 7px',
    background: 'rgba(230, 230, 230, 0.7)',
    lineHeight: '1',
    fontSize: '12px',
    fontFamily: 'monospace',
    letterSpacing: '0',

    '&:before': {
        fontWeight: 'bold',
    },

    '&:after': {
        opacity: 0.7,
    },

    ...onEachBreakpoint((key, condition) => ({
        '&:before': {
            content: `"${key}: "`,
        },

        '&:after': {
            content: `"${condition.replace('@media ', '')}"`,
        },
    })),
});

const StyledLayoutModuleContainer = styled.div(() => ({
    position: 'absolute',
    top: '-1px',
    bottom: '-1px',
    left: 0,
    right: 0,
    border: '1px solid rgba(255, 40, 16, 0.733)',
}));

// Render grid columns overlay for development
export const LayoutOverlay = () => {
    const [layoutOverlayVisible, setLayoutOverlayVisible] = useState(false);
    const [shouldRender, setShouldRender] = useState(false);
    const theme = useTheme();
    const columns = useMemo(() => {
        return Array(theme.gridConfig.columnCount.md)
            .fill(0)
            .map((_, i) => i);
    }, [theme]);

    // Make the component client side only
    // This is to avoid the layout overlay being rendered on the server
    useEffectOnce(() => {
        setShouldRender(true);
    });

    if (!shouldRender) {
        return null;
    }

    return (
        <>
            <StyledLayoutOverlay visibility={layoutOverlayVisible ? 'visible' : 'hidden'}>
                <StyledLayoutColumns>
                    {columns.map((_, index) => (
                        <StyledLayoutColumn key={index} index={index} />
                    ))}
                    <StyledLayoutModuleContainer />
                    <StyledLayoutBreakpointLabel />
                </StyledLayoutColumns>
            </StyledLayoutOverlay>
            <LayoutOverlayButton onToggle={() => setLayoutOverlayVisible(!layoutOverlayVisible)} />
        </>
    );
};
