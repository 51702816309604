import styled from '@emotion/styled';
import { m } from 'framer-motion';
import { Severity } from '~/shared/hooks/useNotification/useNotificationModel';
import { switchProp } from 'styled-tools';
import { breakpoints } from '~/theme';

export const StyledList = styled(m.ul)(({ theme }) => ({
    position: 'fixed',
    bottom: 25,
    left: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 10,
    zIndex: theme.zIndices['max'],
    pointerEvents: 'none',
}));

export const StyledNotification = styled(m.div)(({ theme }) => ({
    background: theme.colors.light,
    color: theme.colors.dark,
    fontSize: theme.fontSizesFluid.sm,
    border: theme.borders.border,
    zIndex: 1,
    overflow: 'hidden',
    pointerEvents: 'auto',
    cursor: 'pointer',
}));

export const StyleContent = styled.div(({ theme }) => ({
    display: 'flex',
    alignItems: 'flex-start',
    gap: theme.spaces[3],
    padding: `${theme.spaces[3]}`,

    [breakpoints.sm]: {
        gap: theme.spaces[4],
    },
}));

export const StyledTextContent = styled.p(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spaces[1],
    width: '20ch',
    flexBasis: '20ch',
    padding: `${theme.spaces[2]} 0`,

    [breakpoints.sm]: {
        width: '30ch',
        flexBasis: '30ch',
    },
}));

export const StyledCloseButton = styled.button(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    background: 'none',
    border: 'none',
    padding: theme.spaces[2],
    margin: `calc(${theme.spaces[2]} * -1)`,
    cursor: 'pointer',
    maxWidth: 580,
    svg: {
        height: 'calc(24em/14)',
        width: 'calc(24em/14)',
    },
}));

export const StyledNotificationIconWrapper = styled.div<{ severity: Severity }>(
    ({ theme }) => ({
        paddingTop: theme.spaces[2],
        svg: {
            height: 'calc(24em/14)',
            width: 'calc(24em/14)',
        },
    }),
    switchProp('severity', {
        error: ({ theme }) => ({
            color: theme.colors.error,
        }),
        warning: ({ theme }) => ({
            color: theme.colors.warning,
        }),
        info: ({ theme }) => ({
            color: theme.colors.information,
        }),
        success: ({ theme }) => ({
            color: theme.colors.success,
        }),
    })
);

export const StyledNotificationTimer = styled(m.div)<{ severity: Severity }>(
    () => ({
        height: '4px',
        transformOrigin: 'left',
    }),
    switchProp('severity', {
        error: ({ theme }) => ({
            backgroundColor: theme.colors.error,
        }),
        warning: ({ theme }) => ({
            backgroundColor: theme.colors.warning,
        }),
        info: ({ theme }) => ({
            backgroundColor: theme.colors.information,
        }),
        success: ({ theme }) => ({
            backgroundColor: theme.colors.success,
        }),
    })
);

export const StyledNotificationList = styled.ul(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spaces[1],
}));
