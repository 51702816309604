import { fetcher } from '~/shared/utils/request/utils/fetcher';

/**
 * Simpler version of a DELETE request, supplies content type and serializes body to json
 */
export const deleteRequest = <ResponseBody = unknown, RequestBody = unknown>(
    url: string,
    body?: RequestBody,
    headers: HeadersInit = {}
) => {
    const opts: RequestInit = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json; charset=utf-8', ...headers },
    };

    if (body) {
        opts['body'] = JSON.stringify(body);
    }

    return fetcher<ResponseBody>(url, opts);
};
