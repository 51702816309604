import { createUser } from '../service/createUser';
import { useClubState } from './useClubState';
import { useMutation } from 'react-query';
import {
    ActivateClubMemberArgs,
    ClubMemberViewModel,
    CreateClubMemberArgs,
    LoginArgs,
    P190LoginPage,
    ResetPasswordArgs,
} from '~/lib/data-contract';
import { useClubPaths } from './useClubPaths';
import { usePageRequest } from '~/shared/utils/request';
import { useNotification } from '~/shared/hooks';
import { useFrame, useTracking } from '~/shared/utils';
import { signIn, useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { useAuthentication } from './useAuthentication';

export const useClub = () => {
    const clubUIState = useClubState();
    const auth = useAuthentication();
    const { push } = useNotification();
    const { data: session } = useSession();
    const { push: routerPush } = useRouter();
    const { trackClubSignUp } = useTracking();
    const {
        createUserUrl,
        signInUrl,
        activateAccountUrl,
        recoverPasswordUrl,
        reAuthenticationUrl,
    } = useClubPaths();
    const { data: frame } = useFrame();
    const loginPageUrl = frame?.staticLinks?.loginPage?.url;
    const { data, isLoading } = usePageRequest<P190LoginPage>(
        loginPageUrl,
        clubUIState.signInActive || clubUIState.signUpActive
    );

    const {
        isLoading: createIsLoading,
        mutateAsync: addCreateUser,
        isSuccess: createIsSuccess,
        data: createData,
    } = useMutation<ClubMemberViewModel, Response, CreateClubMemberArgs>(
        (args) => createUser(args, createUserUrl),
        {
            onSuccess: () => {
                trackClubSignUp();
            },
        }
    );

    const signInUser = async (values: LoginArgs, callbackUrl?: string) => {
        const signInResponse = await signIn(
            'credentials',
            {
                ...values,
                redirect: false,
            },
            { authUrl: signInUrl, reAuthUrl: reAuthenticationUrl }
        );

        if (signInResponse?.error) {
            const errorResponse = JSON.parse(signInResponse.error);
            const { level, errorMessages } = errorResponse;
            push({ severity: level, text: errorMessages });
            return false;
        }

        if (callbackUrl) {
            await routerPush(callbackUrl);
            return true;
        }
    };

    const activateUserSignIn = async (values: ActivateClubMemberArgs, callbackUrl?: string) => {
        const signInResponse = await signIn(
            'activate-credentials',
            {
                values: JSON.stringify(values),
                redirect: false,
            },
            { authUrl: activateAccountUrl, accessToken: session?.accessToken || '' }
        );

        if (signInResponse?.error) {
            const errorResponse = JSON.parse(signInResponse.error);
            const { level, errorMessages } = errorResponse;

            push({ severity: level, text: errorMessages });
            return false;
        }

        if (callbackUrl) {
            // Make sure the new session is used by hard routing
            window.location.href = callbackUrl;
            return true;
        }
    };

    const resetPasswordSignIn = async (values: ResetPasswordArgs, callbackUrl?: string) => {
        const signInResponse = await signIn(
            'reset-password-credentials',
            {
                values: JSON.stringify(values),
                redirect: false,
            },
            { authUrl: recoverPasswordUrl, accessToken: session?.accessToken || '' }
        );

        if (signInResponse?.error) {
            const errorResponse = JSON.parse(signInResponse.error);
            const { level, errorMessages } = errorResponse;
            push({ severity: level, text: errorMessages });
            return false;
        }

        if (callbackUrl) {
            await routerPush(callbackUrl);
        }
        return true;
    };

    return {
        ...clubUIState,
        ...auth,
        loginPage: data,
        loginPageLoading: isLoading,
        createIsLoading,
        addCreateUser,
        createIsSuccess,
        createData,
        signInUser,
        activateUserSignIn,
        resetPasswordSignIn,
    };
};
