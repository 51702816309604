import { Franchises, PagesWithDetail, Redirect } from '~/lib/data-contract';
import { runtimeConfig } from '~/shared/utils/public-variables';
import { fetcher } from './fetcher';

const { API_URL } = runtimeConfig();
/**
 * Helper method for requesting a page from the api. Requires a franchiseId.
 * Returns data
 */
export async function fetchPage(
    url: string,
    franchiseId: Franchises,
    params = {},
    headers: HeadersInit = {}
) {
    const apiUrl = franchiseId
        ? `${API_URL}/api/${franchiseId}/content/page`
        : `${API_URL}/api/content/page`;

    const response = await fetcher<PagesWithDetail | Redirect>(
        apiUrl,
        {
            url,
            ...params,
        },
        headers
    );

    return response.json();
}
