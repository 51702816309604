import { useCallback, useMemo } from 'react';
import { TranslationKey } from '~/lib/data-contract';
import { removeEmpty } from '~/shared/utils';
import { weakKey } from '~/shared/utils/jsx';
import { useTranslation } from '~/shared/utils/translation';
import { Notification, NotificationOptions, Severity } from './useNotificationModel';
import { useNotificationState } from './useNotificationState';

/**
 * Exposes functions and notifications
 * Use to update notification state
 */
export const useNotification = () => {
    const { notifications: stateNotifications, add, remove } = useNotificationState();
    const { translate } = useTranslation();

    const push = (notification: NotificationOptions) => {
        // Make sure we handle empty or undefined messages from endpoints
        // and fallback to defaults
        const notificationProps = removeEmpty(notification);

        const configuredNotification: Notification = {
            headline: '',
            text: '',
            severity: 'error',
            duration: 5000,
            id: weakKey(notification),
            ...notificationProps,
        };

        add(configuredNotification);
    };

    // Check for any missing headline and text, this to make sure that
    // notifications pushed to state also gets default values
    const notifications = useMemo(
        () =>
            stateNotifications.map((notification) => ({
                ...notification,
                headline:
                    notification.headline || translate(getDefaultHeadline(notification.severity)),
                text: notification.text || translate(getDefaultText(notification.severity)),
            })),
        [stateNotifications, translate]
    );

    const dismiss = useCallback((id?: string) => remove(id), [remove]);

    return { notifications, push, dismiss };
};

const getDefaultHeadline = (severity: Severity | undefined): TranslationKey => {
    switch (severity) {
        case 'success':
            return 'global.errorMessages.notification.headlineSuccess';
        case 'warning':
            return 'global.errorMessages.notification.headlineWarning';
        case 'info':
            return 'global.errorMessages.notification.headlineInfo';
        default:
            return 'global.errorMessages.notification.headlineError';
    }
};

const getDefaultText = (severity: Severity | undefined): TranslationKey => {
    switch (severity) {
        case 'success':
            return 'global.errorMessages.notification.textSuccess';
        default:
            // 'warning' and 'info' does not make sense to have default text for, as they should ALWAYS have a correlated text,
            // so this should always be overwritten in the end
            return 'global.errorMessages.notification.textError';
    }
};
