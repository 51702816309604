import { ClubMemberViewModel, UpdateClubMembersArgs } from '~/lib/data-contract';
import { patchRequest } from '~/shared/utils';

export async function editUser(
    payload: UpdateClubMembersArgs,
    apiUrl: string,
    headers?: HeadersInit
) {
    const request = await patchRequest<UpdateClubMembersArgs, ClubMemberViewModel>(
        apiUrl,
        payload,
        headers
    );

    const response = await request();

    return response;
}
