import React from 'react';
import styled from '@emotion/styled';
import { useKeyPressEvent } from 'react-use';
import { StyledResetButton } from '~/shared/components/Button/styled';

const StyledLayoutOverlayButton = styled(StyledResetButton)({
    position: 'fixed',
    right: 0,
    bottom: 0,
    zIndex: 9999991,
    overflow: 'hidden',
    backgroundColor: 'rgba(230, 230, 230, 0.3)',
    fontSize: '12px',
    fontFamily: 'monospace',
    fontWeight: 'normal',
    padding: '5px 8px',
    textTransform: 'uppercase',

    '&:hover, &:focus': {
        backgroundColor: 'rgba(230, 230, 230, 0.7)',
    },
});

/**
 * Fixed on the bottom right corner of the window
 * Press the shortcut 'G' for enabling the grid too
 */
export const LayoutOverlayButton = ({ onToggle }: { onToggle: () => void }) => {
    const onShortcutPress = () => {
        const { activeElement, body } = window.document;
        if (activeElement !== body && activeElement !== null) {
            return;
        }
        onToggle();
    };

    useKeyPressEvent('g', onShortcutPress);

    return <StyledLayoutOverlayButton onClick={onToggle}>Show grid (G)</StyledLayoutOverlayButton>;
};
