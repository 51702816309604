import { useCallback } from 'react';
import { postRequest } from '~/shared/utils';

export const useLogging = () => {
    const customException = useCallback((name: string) => {
        postRequest('/api/logging/exception', { name })();
    }, []);

    return { customException };
};
