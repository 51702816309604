import React, { memo } from 'react';
import { useSchemaMarkup } from './hooks/useSchemaMarkup';
import { useSchemaMarkupProduct } from './hooks/useSchemaMarkupProduct';
import { useSchemaMarkupStore } from './hooks/useSchemaMarkupStore';

export const SchemaMarkup = memo(() => {
    const { pageMarkup, logoMarkup } = useSchemaMarkup();
    const productSchema = useSchemaMarkupProduct();
    const storeSchema = useSchemaMarkupStore();

    return (
        <>
            {storeSchema && (
                <script
                    type="application/ld+json"
                    dangerouslySetInnerHTML={{ __html: storeSchema }}
                />
            )}
            {productSchema && (
                <script
                    type="application/ld+json"
                    dangerouslySetInnerHTML={{ __html: productSchema }}
                />
            )}
            {logoMarkup && (
                <script
                    type="application/ld+json"
                    dangerouslySetInnerHTML={{ __html: logoMarkup }}
                />
            )}
            {pageMarkup && (
                <script
                    type="application/ld+json"
                    dangerouslySetInnerHTML={{ __html: pageMarkup }}
                />
            )}
        </>
    );
});
