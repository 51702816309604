import { Pages } from '~/lib/data-contract';
import { useGetRequest } from '~/shared/utils';
import { useFranchise } from '~/shared/utils/franchise';
import { runtimeConfig } from '~/shared/utils/public-variables';

const { API_URL } = runtimeConfig();
/**
 * Hook which can be used to request CMS page data. Supply page url
 * @example
 * const { data } = usePageRequest('/page/subpage');
 */
export const usePageRequest = <T = Pages>(url?: string, enabled?: boolean) => {
    const { key, activeMarket } = useFranchise();

    const params = url ? { url } : undefined;

    return useGetRequest<T>(`${API_URL}/api/${key}/${activeMarket?.locale}/content/page`, {
        params,
        enabled: enabled !== undefined && !!url ? enabled : !!url,
    });
};
